import { useMutation } from '@tanstack/react-query';
import { ApiBase, sleep } from '@xborglabs/ui-shared';
import { useUserProfile } from '@xborglabs/ui-shared/dist/client';
import { useFormik } from 'formik';
import { AnimatePresence, m } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import styles from '@/modules/shared/components/Template/Popups/content/ApeironSocialBinding/ApeironSocialBindingModal.module.scss';
import { closeModal } from '@/modules/shared/components/Template/Popups/utils/modal';

const formValidationSchema = (t: (tKey: string) => string) =>
  yup.object().shape(
    {
      email: yup
        .string()
        .email(t('profile:apeiron.invalidEmail'))
        .when('walletAddress', {
          is: (walletAddress: string) => !walletAddress,
          then: (schema) =>
            schema.required(t('profile:apeiron.validationDesc')),
          otherwise: (schema) => schema.optional(),
        }),
      walletAddress: yup.string().when('email', {
        is: (email: string) => !email,
        then: (schema) =>
          schema
            .required(t('profile:apeiron.validationDesc'))
            .test(
              'isEthereumAddress',
              t('profile:apeiron.invalidWallet'),
              isValidAddress,
            ),
        otherwise: (schema) => schema.optional(),
      }),
    },
    [['walletAddress', 'email']],
  );

// Custom function to validate Ethereum addresses
function isValidAddress(value: string) {
  const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
  return ethereumAddressRegex.test(value);
}
function ApeironInput({
  hasError,
  error,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & {
  hasError: boolean;
  error?: string;
}) {
  return (
    <div className={styles.apeiron__input_wrapper}>
      <input
        className={`${styles.apeiron__input} ${
          hasError ? styles.apeiron__input_error : ''
        }`}
        {...props}
      />
      <AnimatePresence>
        {hasError && (
          <m.p
            className={styles.apeiron__error}
            initial={{
              height: 0,
              y: -3,
            }}
            animate={{
              height: 'auto',
              y: 0,
            }}
          >
            {error}
          </m.p>
        )}
      </AnimatePresence>
    </div>
  );
}

export function ApeironSocialBindingModal({
  onClose,
}: {
  onClose?: () => void;
}) {
  const { t } = useTranslation(['profile']);
  const { refetch } = useUserProfile();
  const formik = useFormik({
    initialValues: {
      email: '',
      walletAddress: '',
    },
    onSubmit: handleApeironBindSubmission,
    validationSchema: formValidationSchema(t),
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });
  const mutation = useBindApeironMutation();
  function handleApeironBindSubmission() {
    mutation.mutate(
      {
        email: formik.values.email,
        walletAddress: formik.values.walletAddress,
      },
      {
        onSuccess: async () => {
          formik.resetForm();
          await refetch();
          closeModal();
          await sleep(300);
          onClose?.();
        },
        onError: () => {
          formik.validateForm();
        },
      },
    );
  }
  return (
    <div className={styles.apeiron}>
      <h1 className={styles.apeiron__heading}>{t('profile:apeiron.title')}</h1>
      <form
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
        className={styles.apeiron__form}
      >
        <div className={styles.apeiron__card}>
          <p className={styles.apeiron__body}>
            {t('profile:apeiron.description')}
          </p>

          <ApeironInput
            placeholder={'Email'}
            type="email"
            hasError={Boolean(formik.dirty && formik.errors?.email)}
            error={formik.errors?.email}
            value={formik.values.email}
            onChange={formik.handleChange('email')}
          />
          <ApeironInput
            type="text"
            name="walletAddress"
            placeholder="Wallet address"
            hasError={Boolean(formik.dirty && formik.errors?.walletAddress)}
            error={formik.errors?.walletAddress}
            value={formik.values.walletAddress}
            onChange={formik.handleChange('walletAddress')}
          />
        </div>
        <div className={styles.apeiron__button_wrapper}>
          <Button
            variant={BUTTON_VARIANT.RED}
            size={BUTTON_SIZE.LARGE}
            className={styles.apeiron__button}
            loading={mutation.isPending}
            disabled={mutation.isPending || !formik.isValid}
            onClick={formik.submitForm}
          >
            {t('profile:apeiron.ctaLabel')}
          </Button>
        </div>
      </form>
    </div>
  );
}

function useBindApeironMutation() {
  const api = ApiBase.getInstance().getApi();
  const { t } = useTranslation(['profile']);

  return useMutation({
    mutationFn: async ({
      email,
      walletAddress,
    }: {
      email: string;
      walletAddress: string;
    }) => {
      const response = await api
        .post(
          process.env.NEXT_PUBLIC_FRONTEND_URL +
            '/api/user/socials/link/apeiron/start',
          {
            ...(email ? { email } : null),
            ...(walletAddress ? { walletAddress } : null),
          },
        )
        .then((r) => r.data);
      return response;
    },
    onSuccess: () => {
      toast.error(t('profile:apeiron.successToast'));
    },
    onError: () => {
      toast.error(t('profile:apeiron.failToast'));
    },
  });
}
