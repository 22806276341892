import { shouldDisplayCompleteButton } from '@xborglabs/ui-shared';
import {
  QuestDisabledReason,
  guardQuestSubmit,
  useMutateCompleteQuest,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { MouseEvent } from 'react';

import { toast } from '@/lib/toastify';
import { QuestLimit } from '@/modules/quests/components';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import { QuestRewards } from '../QuestRewards/QuestRewards';

export const ReferralsQuestFooter = ({
  disabled,
}: {
  disabled?: boolean;
  payload?: any;
}) => {
  const { t } = useTranslation(['quests', 'errors']);
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const referred = actionContext.context.authState?.referredList;
  const referrals = referred?.length ?? 0;
  const mutateCompleteQuest = useMutateCompleteQuest();

  function completeQuest(e: MouseEvent) {
    e.preventDefault();
    const id = quest?.questId;
    if (!id) return;
    mutateCompleteQuest.mutate(
      { id },
      {
        onSuccess: () => {
          openModal(MODAL.QUEST_PENDING, {
            qid: { id },
          });
        },
        onError: () => {
          toast.error(t('errors:globalErrors.quest_complete_rejected'));
        },
      },
    );
  }

  function handleOnClick(e: MouseEvent) {
    guardQuestSubmit(actionContext.reason, () => completeQuest(e));
  }

  if (!quest) return null;

  return (
    <>
      <div className="lexend-body-xs1">
        <QuestLimit
          attemptCount={quest.attemptCount}
          attemptLimit={quest.attemptLimit}
        />
      </div>
      <div className="footer-action">
        <div className="footer-reward">
          <QuestRewards />
          <div className="total-participants lexend-body-xs1">
            {t('quests:participants', { count: referrals })}
          </div>
        </div>
        <Button
          id={'referrals-quest-button'}
          variant={BUTTON_VARIANT.BLUE}
          className="final-action"
          size={BUTTON_SIZE.LARGE}
          loading={mutateCompleteQuest.isPending}
          disabled={disabled || mutateCompleteQuest.isPending}
          onClick={handleOnClick}
        >
          <ReferralsQuestButtonLabel />
        </Button>
      </div>
    </>
  );
};

function ReferralsQuestButtonLabel() {
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const displayComplete = shouldDisplayCompleteButton(
    actionContext.context.quest?.status,
    actionContext.context.quest?.template.name,
  );

  if (actionContext.reason === QuestDisabledReason.MAX_ATTEMPTS_REACHED) {
    return <>{t('maxAttemptsReached')}</>;
  }
  if (actionContext.reason === QuestDisabledReason.QUEST_COMPLETED) {
    return <>{t('buttonLabel.questCompleted')}</>;
  }
  if (actionContext.reason === QuestDisabledReason.QUEST_FAILED) {
    return <>{t('questFailed')}</>;
  }

  if (displayComplete) {
    return <>{t('buttonLabel.verify')}</>;
  }

  return null;
}
