import { USER_SOCIAL_TYPE } from '@xborglabs/ui-shared';
import {
  useUserProfile,
  useWowCharacters,
} from '@xborglabs/ui-shared/dist/client';

export function useWorldOfWarcraftCharacters() {
  const { data: authState } = useUserProfile();
  const battlenet = authState?.socials?.find(
    (social) => social.accountType === USER_SOCIAL_TYPE.BATTLENET,
  );

  return useWowCharacters({
    enabled: !!battlenet,
  });
}
