export const Images = {
  logo: '/images/logo.svg',
  logo_full: '/images/logo_full.svg',
  rain: '/images/rain.png',

  // Community
  communityview: {
    banner: '/images/communityview/explore-bg.png',
    logo: '/images/communityview/communitylogo.png',
    platform: '/images/communityview/platform.png',
  },

  // Gaming
  gameview: {
    banner: '/images/gameview/header.jpg',
    logo: '/images/gameview/logo.png',
  },

  // Landing
  landing: {
    bds: '/images/landing/bds.png',
    bdsLogo: '/images/landing/bdslogo.png',
    tournament: '/images/landing/tournament.png',
    dojo: '/images/landing/dojo.jpg',

    cards: {
      card01: '/images/landing/csgo.png',
      card02: '/images/landing/dota2.png',
      card03: '/images/landing/valorant.png',
      card04: '/images/landing/lol.png',
    },

    ongoingEvent: '/images/landing/ongoing_event.jpg',

    communities: {
      bds: '/images/landing/teambds.png',
      nip: '/images/landing/teamnip.png',
    },
  },

  // Claim username
  claimusername: {
    wallpaper: '/images/claimusername/wallpaper.jpg',
  },

  // Leaderboard
  leaderboard: {
    gold: '/images/leaderboard/gold.png',
    silver: '/images/leaderboard/silver.png',
    bronze: '/images/leaderboard/bronze.png',
    default: '/images/leaderboard/default.png',
    empty: '/images/leaderboard/empty-leaderboard.png',
  },

  // Referral
  referral: {
    header: '/images/referrals/header.jpg',
  },

  points: {
    points_blue: '/images/pointsbar/points_blue.png',
    wearable: '/images/pointsbar/wearable.svg',
  },

  events: {
    cover: '/images/gameview/event.jpg',
    empty: '/images/empty-events.png',
  },

  badge: {
    bg: '/images/gameview/badgebg.png',
    badge: '/images/gameview/badge.png',
    frame: '/images/gameview/frame.png',
    character: '/images/gameview/character.png',
  },

  quests: {
    empty: '/images/empty-quests.svg',
  },

  inventory: {
    skeleton: '/images/inventory/skeleton.svg',
  },

  maintenance: {
    cyberpunkCitizen: '/images/CyberpunkCitizenComb_V01b_Pose3.png',
  },

  // Quest icons
  questIcon: {
    'answer-submission': '/images/quest/answer-sub.png',
    'auto-complete': '/images/quest/autocomplete.png',
    'check-resource': '/images/quest/resource.png',
    'image-submission': '/images/quest/image.png',
    'membership-referral-count': '/images/quest/comm-ref.png',
    'referral-count': '/images/quest/ref-count.png',
    'text-submission': '/images/quest/text-sub.png',
    'visit-link': '/images/quest/visit-link.png',
    'check-level': '/images/quest/resource.png',
    apeiron: '/images/quest/apeiron.png',
    battlenet: '/images/quest/battlenet.png',
    bushi: '/images/quest/bushi.png',
    cs2: '/images/quest/cs2.png',
    discord: '/images/quest/discord.png',
    dota2: '/images/quest/dota2.png',
    lol: '/images/quest/lol.png',
    overwatch: '/images/quest/overwatch.png',
    poll: '/images/quest/poll.png',
    quiz: '/images/quest/quiz.png',
    steam: '/images/quest/steam.png',
    tft: '/images/quest/tft.png',
    twitch: '/images/quest/twitch.png',
    twitter: '/images/quest/x.png',
    youtube: '/images/quest/youtube.png',
  },
};
