import {
  ACCEPTONLY_QUEST_TEMPLATE_NAME,
  COMPLETEONLY_QUEST_TEMPLATE_NAME,
  QuestsFiltersType,
} from '@xborglabs/ui-shared';
import {
  QuestDisabledReason,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { P, match } from 'ts-pattern';

import {
  AnswerSubmissionMission,
  BattlenetQuestRequirement,
  CheckResourceMission,
  CommunityReferralMission,
  DiscordRoleMission,
  DiscordServerMission,
  ImageSubmissionMission,
  LoadingRequirement,
  QuestPollResults,
  QuestPreconditionRequirement,
  QuestResolutionNotice,
  QuizQuest,
  ReferralCountMission,
  RiotQuestRequirement,
  SteamQuestRequirement,
  SteamWishlistMission,
  TextSubmissionMission,
  TwitchFollowMission,
  TwitchSubscribeMission,
  TwitterFollowMission,
  TwitterLikeMission,
  TwitterQuoteMission,
  TwitterRetweetMission,
  TwitterTweetHasHashtagMission,
  VisitLinkMission,
  YoutubeLikeMission,
  YoutubeSubscribeMission,
} from '@/modules/quests/components';
import { WowQuestRequirement } from '@/modules/quests/components/QuestRequirement/battlenet/WowQuestRequirement/WowQuestRequirement';
import { Alert } from '@/modules/shared/components/Molecule/Alert';

import { ApeironQuestRequirement } from './apeiron/ApeironQuestRequirement';
import { CheckLevel } from './basic/CheckLevel';

export type QuestRequirementType = {
  setDisabled?: (val: boolean) => void;
  setPayload?: (val: Object) => void;
  setFooter?: (val: boolean) => void;
  filters?: QuestsFiltersType;
  hasPrecondition?: boolean;
  disabled?: boolean;
};

export const QuestRequirement = (props: QuestRequirementType) => {
  const actionContext = useQuestActionContext();
  const questTemplateName = actionContext.context.quest?.template.name;

  if (
    actionContext.reason === QuestDisabledReason.QUEST_COMPLETED ||
    actionContext.reason === QuestDisabledReason.QUEST_FAILED
  ) {
    return <NoActionPossible />;
  }

  const className = actionContext.context.quest?.template.name.split('.').pop();

  return (
    <div
      className={`quest-requirements ${className} ${
        actionContext.context.quest?.status?.toLowerCase() ?? 'loading'
      }`}
    >
      {actionContext.reason ===
        QuestDisabledReason.QUEST_PRECONDITION_NOT_MET && (
        <QuestPreconditionRequirement
          data={actionContext.context.quest?.parent}
        />
      )}
      <QuestResolutionNotice quest={actionContext.context.quest} />
      <QuestRequirements
        questTemplateName={questTemplateName}
        payload={props}
      />
    </div>
  );
};

function NoActionPossible() {
  const actionContext = useQuestActionContext();
  const { t } = useTranslation(['quests']);

  const MessageElement = match(actionContext.reason)
    .with(QuestDisabledReason.QUEST_COMPLETED, () => (
      <Alert variant="info">{t('quests:questCompleted')}</Alert>
    ))
    .with(QuestDisabledReason.QUEST_FAILED, () => (
      <Alert variant="error">{t('quests:questFailedDescription')}</Alert>
    ))
    .otherwise(() => null);

  return (
    <div>
      {COMPLETEONLY_QUEST_TEMPLATE_NAME.POLL_QUEST ===
        actionContext.context.quest?.template.name && (
        <QuestPollResults data={actionContext.context.quest} />
      )}

      <div style={{ marginTop: 12 }}>{MessageElement}</div>
    </div>
  );
}

function QuestRequirements({
  questTemplateName,
  payload,
}: {
  questTemplateName?: string;
  payload: QuestRequirementType;
}) {
  if (!questTemplateName) {
    return null;
  }

  const Requirements = match(questTemplateName)
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.TWITTER_FOLLOW,
      () => TwitterFollowMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.TWITTER_RETWEET,
      () => TwitterRetweetMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.TWITTER_QUOTE,
      () => TwitterQuoteMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.TWITTER_TWEET_HAS_HASHTAG,
      () => TwitterTweetHasHashtagMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.TWITTER_TWEET_HAS_LINK,
      () => TwitterTweetHasHashtagMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.TWITTER_LIKE,
      () => TwitterLikeMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.DISCORD_CHECKROLE,
      () => DiscordRoleMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.DISCORD_CHECKGUILD,
      () => DiscordServerMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.TWITCH_FOLLOW,
      () => TwitchFollowMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.TWITCH_SUBSCRIBE,
      () => TwitchSubscribeMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.YOUTUBE_LIKE,
      () => YoutubeLikeMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.YOUTUBE_SUBSCRIBE,
      () => YoutubeSubscribeMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.REFERRAL_COUNT,
      () => ReferralCountMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.CHECK_RESOURCE,
      () => CheckResourceMission,
    )
    .with(
      P.union(
        COMPLETEONLY_QUEST_TEMPLATE_NAME.POLL_QUEST,
        COMPLETEONLY_QUEST_TEMPLATE_NAME.QUIZ_QUEST,
      ),
      () => QuizQuest,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.ANSWER_SUBMISSION,
      () => AnswerSubmissionMission,
    )
    .with(COMPLETEONLY_QUEST_TEMPLATE_NAME.VISIT_LINK, () => VisitLinkMission)
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.STEAM_WISHLIST,
      () => SteamWishlistMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.COMMUNITY_REFERRAL,
      () => CommunityReferralMission,
    )
    .with(COMPLETEONLY_QUEST_TEMPLATE_NAME.CHECK_LEVEL, () => CheckLevel)
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.AUTO_COMPLETE,
      () =>
        (function AutoComplete() {
          return <></>;
        }),
    )
    .with(
      ACCEPTONLY_QUEST_TEMPLATE_NAME.TEXT_SUBMISSION,
      () => TextSubmissionMission,
    )
    .with(
      ACCEPTONLY_QUEST_TEMPLATE_NAME.IMAGE_SUBMISSION,
      () => ImageSubmissionMission,
    )
    .with(
      COMPLETEONLY_QUEST_TEMPLATE_NAME.APEIRON_PLAY_10_MINUTES,
      () => ApeironQuestRequirement,
    )
    .when(
      (v) =>
        v.startsWith('cs2.') ||
        v.startsWith('dota2.') ||
        v.startsWith('steam.'),
      () => SteamQuestRequirement,
    )
    .when(
      (v) => v.startsWith('tft.') || v.startsWith('lol.'),
      () => RiotQuestRequirement,
    )
    .when(
      (v) => v.startsWith('bnet.') || v.startsWith('overwatch.'),
      () => BattlenetQuestRequirement,
    )
    .when(
      (v) => v.startsWith('apeiron.'),
      () => ApeironQuestRequirement,
    )
    .when(
      (v) => v.startsWith('wow.'),
      () => WowQuestRequirement,
    )
    .otherwise(() => LoadingRequirement);

  return <Requirements {...payload} />;
}
