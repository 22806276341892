import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { Images } from '@/assets/imgs';
import { Videos } from '@/assets/videos';
import { getServerSidei18nProps } from '@/lib/serverSideProps/getServerSidei18nProps';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { Layout } from '@/modules/shared/components/Template/Containers';

import './_maintenance.scss';

export default function MaintenancePage() {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Layout overrideBorderState="none" overrideFeature={true}>
      <div className="maintainance-page">
        {isClient && (
          <video
            className="video-bg"
            src={Videos.RAIN}
            autoPlay
            loop
            muted
          ></video>
        )}

        <Image
          src={Images.maintenance.cyberpunkCitizen}
          alt=""
          width={1612}
          height={1063}
          className="cyberpunk-citizen"
        />

        <div className="container flex column between">
          <div className="navigator">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="171"
              height="32"
              viewBox="0 0 171 32"
              fill="none"
            >
              <path
                d="M53.7666 16.2288V23.565H51.6067L51.558 21.6456C50.616 23.0282 48.9109 24.0205 46.4911 24.0205C42.35 24.0205 39.167 21.0437 39.167 16.733C39.167 12.4061 42.2201 9.44556 46.7997 9.44556C49.8528 9.44556 52.9383 10.8933 53.6042 14.4069L49.9665 14.4069C49.528 13.3496 48.3912 12.6176 46.8647 12.6176C44.4936 12.6176 42.8047 14.293 42.8047 16.733C42.8047 19.173 44.5261 20.8485 46.7672 20.8485C47.8878 20.8485 49.7553 20.393 50.2101 18.4736H46.5723V16.2288L53.7666 16.2288Z"
                fill="white"
              />
              <path
                d="M66.2321 23.565L65.4689 21.4341H59.0379L58.2746 23.565L54.4908 23.565L59.6875 9.90102H64.8193L70.016 23.565L66.2321 23.565ZM60.0123 18.685H64.4945L62.5944 13.3496H61.9286L60.0123 18.685Z"
                fill="white"
              />
              <path
                d="M70.8308 23.565V9.90102H75.4916L78.4797 18.8802H78.7558L81.7439 9.90102H86.4047V23.565H82.8969V16.0661H82.5884L80.0225 23.565H77.213L74.6471 16.0661H74.3386V23.565H70.8308Z"
                fill="white"
              />
              <path
                d="M87.7113 23.565V9.90102L99.4689 9.90102V12.8778L91.3815 12.8778V15.448L99.1766 15.448V18.0018H91.3815V20.5882H99.4689V23.565H87.7113Z"
                fill="white"
              />
              <path
                d="M114.229 23.565L110.608 23.565L108.513 19.6773H104.437V23.565H100.767V9.90102H108.156C111.842 9.90102 113.856 11.5765 113.856 14.8949C113.856 16.7818 112.963 18.1482 111.647 18.929L114.229 23.565ZM104.437 12.9592V16.8957H107.928C109.406 16.8957 110.202 16.2125 110.202 14.9274C110.202 13.6424 109.406 12.9592 107.928 12.9592H104.437Z"
                fill="white"
              />
              <path
                d="M126.257 16.424C127.686 17.0584 128.514 18.0994 128.514 19.7098C128.514 21.9384 126.939 23.565 122.847 23.565H115.23V9.90102L122.928 9.90102C126.907 9.90102 128.108 11.5765 128.108 13.4472C128.108 14.9274 127.361 15.692 126.257 16.2125V16.424ZM122.619 12.7314L118.9 12.7314V15.448L122.619 15.448C123.951 15.448 124.6 14.9274 124.6 14.1141C124.6 13.2682 123.951 12.7314 122.619 12.7314ZM122.765 20.7184C124.113 20.7184 124.812 20.0352 124.812 19.1242C124.812 18.2296 124.113 17.6277 122.765 17.6277H118.9V20.7184H122.765Z"
                fill="white"
              />
              <path
                d="M140.339 23.565L139.576 21.4341H133.145L132.381 23.565H128.597L133.794 9.90102H138.926L144.123 23.565L140.339 23.565ZM134.119 18.685H138.601L136.701 13.3496H136.035L134.119 18.685Z"
                fill="white"
              />
              <path
                d="M150.592 24.0205C146.24 24.0205 144.031 22.5402 144.08 19.2218L147.393 19.2218C147.474 20.3768 148.448 21.125 150.592 21.125C152.573 21.1413 153.596 20.5069 153.596 19.5797C153.596 18.7989 153.012 18.3272 151.485 18.0832L149.78 17.7904C147.182 17.3512 144.437 16.7818 144.437 13.6424C144.437 11.0234 146.629 9.44556 150.608 9.44556C154.295 9.44556 156.844 10.633 156.844 14.1954H153.564C153.369 13.073 152.476 12.3248 150.543 12.3248C148.806 12.3248 147.961 12.9104 147.961 13.805C147.961 14.5045 148.481 15.0088 149.748 15.2202L151.225 15.4805C153.856 15.9522 157.234 16.2776 157.234 19.6936C157.234 22.5077 154.977 24.0368 150.592 24.0205Z"
                fill="white"
              />
              <path
                d="M158.259 23.565V9.90102L170.017 9.90102V12.8778L161.929 12.8778V15.448L169.725 15.448V18.0018H161.929V20.5882H170.017V23.565H158.259Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.6931 25.7409C30.8376 22.9464 32 19.5224 32 16C32 15.0367 32 14.555 31.8119 14.1873C31.6479 13.8667 31.387 13.6058 31.0664 13.4418C30.6987 13.2537 30.217 13.2537 29.2537 13.2537H26.6269C25.6636 13.2537 25.1819 13.2537 24.8142 13.4418C24.4935 13.6058 24.2327 13.8667 24.0687 14.1873C23.8806 14.555 23.8806 15.0367 23.8806 16C23.8806 17.7361 23.3073 19.4236 22.2498 20.8004C21.1923 22.1772 19.7098 23.1663 18.0324 23.614C16.3551 24.0617 14.5769 23.9431 12.9739 23.2764C11.3709 22.6098 10.0329 21.4325 9.16776 19.9274C8.30258 18.4222 7.95862 16.6735 8.1893 14.9529C8.41998 13.2322 9.21239 11.6358 10.4435 10.4118C11.6745 9.18767 13.2754 8.40436 14.9973 8.18347C15.5655 8.11059 16.1363 8.10028 16.7001 8.15057C18.7382 8.33237 19.7573 8.42328 20.2012 8.19276C20.6452 7.96223 20.9831 7.37763 21.6588 6.20841L22.2904 5.11554C23.123 3.67491 23.5392 2.9546 23.2438 2.18943C22.9483 1.42427 22.2578 1.20331 20.8769 0.761388C18.6447 0.047026 16.269 -0.172551 13.9175 0.136122C10.4251 0.594581 7.18149 2.19273 4.68995 4.68267C2.19841 7.17261 0.598172 10.4152 0.137465 13.9073C-0.323242 17.3995 0.381334 20.9461 2.1419 23.997C3.90246 27.0479 6.62061 29.4326 9.8747 30.7811C13.1288 32.1296 16.7369 32.3666 20.1395 31.4552C23.542 30.5439 26.5487 28.5353 28.6931 25.7409Z"
                fill="#EB3A4A"
              />
              <path
                d="M20.656 13.2538H27.1069C28.3391 13.2538 28.9553 13.2538 29.3942 13.5558C29.5667 13.6745 29.716 13.8238 29.8346 13.9962C30.1367 14.4352 30.1367 15.0513 30.1367 16.2836C30.1367 17.5159 30.1367 18.132 29.8346 18.571C29.716 18.7434 29.5667 18.8927 29.3942 19.0114C28.9553 19.3135 28.3391 19.3135 27.1069 19.3135H20.656C18.658 19.3135 17.6591 19.3135 17.1537 18.7907C16.9596 18.5899 16.8175 18.3447 16.7397 18.0764C16.5373 17.378 17.0338 16.5112 18.0269 14.7776C18.3957 14.1339 18.5801 13.812 18.8595 13.6007C18.971 13.5164 19.0924 13.446 19.221 13.3912C19.5432 13.2538 19.9141 13.2538 20.656 13.2538Z"
                fill="#EB3A4A"
              />
            </svg>
          </div>

          <div className="footer flex column top">
            <Link href="" className="external-link flex middle center">
              <Icon.twitter size={24} />

              <span>GamerBase</span>

              <Icon.externalLink size={24} />
            </Link>
            <div className="coming-soon">We will be back soon.</div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const getServerSideProps = getServerSidei18nProps;
