import {
  QUEST_STATUS,
  QUEST_STATUS_INFO,
  isAutoCompleteManual,
} from '@xborglabs/ui-shared';
import {
  QuestDisabledReason,
  QuestRequiredAction,
  useMutateSubmitImageQuest,
  useQuestActionContext,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { P, match } from 'ts-pattern';

import {
  QuestRequirementType,
  QuestRewards,
} from '@/modules/quests/components';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { Alert } from '@/modules/shared/components/Molecule/Alert/Alert';
import {
  FileSelect,
  FileType,
} from '@/modules/shared/components/Molecule/FileSelect';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import { DisplayParticipants } from '../../../QuestDetails/DisplayParticipants/DisplayParticipants';

export const ImageSubmissionMission = ({ setFooter }: QuestRequirementType) => {
  const [files, setFiles] = useState<File[]>([]);
  const { t } = useTranslation(['quests']);
  const actionContext = useQuestActionContext();
  const quest = actionContext.context.quest;
  const mutateImageSubmission = useMutateSubmitImageQuest();
  const isDisabled = files.length === 0;
  const isFileUploadDisabled =
    actionContext.action === QuestRequiredAction.STOP;
  const errorMessage = useMemo(() => {
    return match(actionContext.reason)
      .with(QuestDisabledReason.GUEST_USER, () => t('quests:loginFirstQuest'))
      .with(QuestDisabledReason.COMMUNITY_GUEST, () =>
        t('quests:questOnlyForMembers'),
      )
      .with(QuestDisabledReason.EVENT_GUEST, () =>
        t('quests:registerEventFirst'),
      )
      .with(QuestDisabledReason.EVENT_JOIN_NOT_ALLOWED, () =>
        t('quests:eventJoinNotAllowed'),
      )
      .otherwise(() => null);
  }, [actionContext.reason, t]);

  useEffect(() => {
    setFooter?.(false);
  }, []);
  async function handleImageSubmission() {
    mutateImageSubmission.mutate({
      questId: quest?.questId ?? '',
      files,
    });

    if (isAutoCompleteManual(quest?.template)) {
      openModal(MODAL.QUEST_PENDING, {
        qid: {
          id: quest?.questId ?? '',
        },
      });
    }
  }

  return (
    <div className="image-submission">
      {match({ ...quest, errorMessage })
        .with({ errorMessage: P.not(P.nullish) }, () => (
          <Alert variant="info">{errorMessage}</Alert>
        ))
        .with({ status: QUEST_STATUS.COMPLETED }, () => <QuestCompleted />)
        .with({ status: QUEST_STATUS.FAILED }, () => <QuestFailed />)
        .with(
          P.union(
            { status: QUEST_STATUS.RESOLVING },
            { statusInfo: QUEST_STATUS_INFO.RETRYING }, // Quest is being retried
            { statusInfo: QUEST_STATUS_INFO.VALIDATION_SUCCESSFUL }, // Quest validation is successful (pre-review)
          ),
          () => <QuestInReview />,
        )
        .otherwise(() => (
          <FileUpload
            files={files}
            setFiles={setFiles}
            isDisabled={isFileUploadDisabled}
          />
        ))}

      <div className="flex between quest-data">
        <QuestRewards />
      </div>

      <div className="quest-details__footer flex end middle">
        <div className="footer-action ">
          <div className="footer-reward">
            <QuestRewards />
            <div className="total-participants lexend-body-xs1">
              <DisplayParticipants />
            </div>
          </div>
          {!errorMessage &&
            match(quest)
              .with(
                { status: QUEST_STATUS.COMPLETED, completedAt: P.any },
                () => (
                  <Button
                    variant={BUTTON_VARIANT.BLUE}
                    className="final-action"
                    size={BUTTON_SIZE.LARGE}
                    disabled
                  >
                    {t('quests:questCompleted')}
                  </Button>
                ),
              )
              .with(
                P.union(
                  { status: QUEST_STATUS.RESOLVING },
                  { statusInfo: QUEST_STATUS_INFO.VALIDATION_SUCCESSFUL },
                ),
                P.nullish,
                () => (
                  <Button
                    variant={BUTTON_VARIANT.TRANSPARENT}
                    className="in-review"
                    size={BUTTON_SIZE.LARGE}
                    disabled
                  >
                    {t('quests:inReview')}
                  </Button>
                ),
              )

              .otherwise(() => (
                <Button
                  variant={BUTTON_VARIANT.BLUE}
                  className="final-action"
                  size={BUTTON_SIZE.LARGE}
                  loading={mutateImageSubmission.isPending}
                  disabled={
                    isDisabled ||
                    mutateImageSubmission.isPending ||
                    actionContext.action === QuestRequiredAction.STOP
                  }
                  onClick={handleImageSubmission}
                >
                  {actionContext.reason ===
                  QuestDisabledReason.MAX_ATTEMPTS_REACHED
                    ? t('quest:maxAttemptsReached')
                    : t('quests:submit')}
                </Button>
              ))}
        </div>
      </div>
    </div>
  );
};

function FileUpload({
  files,
  setFiles,
  isDisabled,
}: {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  isDisabled: boolean;
}) {
  const { t } = useTranslation(['quests']);
  return (
    <FileSelect
      files={files}
      setFiles={setFiles}
      fileType={FileType.IMAGE}
      isDisabled={isDisabled}
    >
      <div className="flex column middle center w-full">
        <Icon.upload size={32} />

        <div className="lexend-body-xl">{t('quests:uploadImage')}</div>
      </div>
    </FileSelect>
  );
}

function QuestInReview() {
  const { t } = useTranslation(['quests']);
  return <Alert variant="info">{t('quests:inReviewDescription')}</Alert>;
}

function QuestFailed() {
  const { t } = useTranslation(['quests']);
  return <Alert variant="info">{t('quests:questFailedDescription')}</Alert>;
}

function QuestCompleted() {
  const { t } = useTranslation(['quests']);

  return (
    <div className="alert lexend-body-xs1 flex middle">
      <Icon.check size={16} />
      <span>{t('quests:questCompletedDescription')}</span>
    </div>
  );
}
