import { useQueryClient } from '@tanstack/react-query';
import {
  CLIENT_QUERY_KEYS,
  COMPLETEONLY_QUEST_TEMPLATE_NAME,
  QUEST_STATUS,
  QUEST_STATUS_INFO,
  QuestDetailsType,
} from '@xborglabs/ui-shared';
import { useQuestDetail } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';
import { P, match } from 'ts-pattern';

import { Videos } from '@/assets/videos';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { PopupClose } from '@/modules/shared/components/Template/Popups';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';
import './_QuestPending.scss';

const MAX_RETRY = 5;
const RETRY_INTERVAL = 2000;

type StatusProps = {
  onBack: () => any;
  questData?: QuestDetailsType;
};

function QuestPendingContent() {
  const { t } = useTranslation(['quests']);
  return (
    <div className="quest-pending-popup  flex column center">
      <PopupClose />
      <video
        src={Videos.GAMERBASE}
        autoPlay
        loop
        muted
        className="gb-loading"
      />
      <h3 className="lexend-heading-h3 font-bold">
        {t('quests:questIsResolving')}
      </h3>
      <div className="quest-pending-popup__content">
        <p className="quest-pending-popup__description">
          {t('quests:questIsResolvingSubtitle')}
        </p>
      </div>
    </div>
  );
}

function QuestFailedPopup({ onBack, questData }: StatusProps) {
  const { t } = useTranslation(['quests']);

  return (
    <div className="quest-pending-popup  flex column center">
      <PopupClose />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="65"
        height="64"
        viewBox="0 0 65 64"
        fill="none"
        className={'symbol'}
      >
        <path
          d="M4.46364 57.0588L32.5 8.49834L60.5364 57.0588H4.46364Z"
          stroke="#FE8D25"
          strokeWidth="5"
        />
        <path
          d="M30.9287 42.4692C30.7574 41.1636 30.5862 39.8151 30.415 38.4239C30.2437 37.0326 30.1046 35.652 29.9976 34.2822C29.8906 32.9123 29.837 31.5959 29.837 30.3331V27.8288H35.263V30.3331C35.263 31.6173 35.2095 32.9551 35.1025 34.3464C34.9954 35.7376 34.8563 37.1289 34.6851 38.5202C34.5138 39.89 34.3319 41.2064 34.1393 42.4692H30.9287ZM32.534 51.0095C31.6564 51.0095 30.9715 50.7633 30.4792 50.271C29.9869 49.7787 29.7407 49.0724 29.7407 48.152C29.7407 47.2959 29.9976 46.6002 30.5113 46.0651C31.025 45.53 31.6992 45.2625 32.534 45.2625C33.4115 45.2625 34.0858 45.5193 34.5567 46.033C35.0489 46.5253 35.2951 47.2317 35.2951 48.152C35.2951 48.9868 35.0382 49.6717 34.5245 50.2068C34.0108 50.7419 33.3473 51.0095 32.534 51.0095Z"
          fill="white"
        />
      </svg>

      <h3 className="lexend-heading-h3 font-bold">
        {t('quests:couldNotResolveQuest')}
      </h3>
      <div className="quest-failed-popup__content">
        <p className="quest-failed-popup__description">
          {match(questData)
            .with(
              P.union({
                status: QUEST_STATUS.ACTIVE,
                statusInfo: QUEST_STATUS_INFO.OAUTH_TOKEN_REFRESH_FAILED,
              }),
              () => t('quests:couldNotResolveQuestOauthExpiredDescription'),
            )
            .otherwise(() => t('quests:couldNotResolveQuestDescription'))}
        </p>
      </div>
      <Button
        variant={BUTTON_VARIANT.TRANSPARENT}
        size={BUTTON_SIZE.SMALL}
        onClick={onBack}
      >
        Back to quest
      </Button>
    </div>
  );
}

export function QuestPending({ qid: { id } }: { qid: { id: string } }) {
  const [retries, setRetries] = useState(1);
  const { data, refetch } = useQuestDetail(id);
  const queryClient = useQueryClient();
  const isCompleted = data?.status === QUEST_STATUS.COMPLETED;
  const isFailed = data?.status === QUEST_STATUS.FAILED;

  const retryCall = useCallback(
    () =>
      setInterval(() => {
        refetch();
        setRetries((r) => r + 1);
      }, RETRY_INTERVAL),
    [refetch],
  );

  // Clear interval and stop requesting when timer reaches end.
  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined;
    if (retries <= MAX_RETRY && !isCompleted) {
      intervalId = retryCall();
    } else if (intervalId) {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [data?.status, isCompleted, retries, retryCall]);

  const shouldAutoReturnToQuest = () => {
    if (data?.template.name === COMPLETEONLY_QUEST_TEMPLATE_NAME.POLL_QUEST) {
      return !data.template.args.isPrivate;
    }

    return false;
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (isCompleted) {
      const isReturning = shouldAutoReturnToQuest();

      queryClient.invalidateQueries({
        queryKey: [CLIENT_QUERY_KEYS.COMMUNITY_QUESTS],
      });
      queryClient.invalidateQueries({
        queryKey: [CLIENT_QUERY_KEYS.COMMUNITY_QUEST_FILTERS],
      });
      queryClient.invalidateQueries({
        queryKey: [CLIENT_QUERY_KEYS.COMMUNITY_RANKING],
      });
      queryClient.invalidateQueries({
        queryKey: [CLIENT_QUERY_KEYS.COMMUNITY_INVENTORY],
      });
      queryClient.invalidateQueries({
        queryKey: [CLIENT_QUERY_KEYS.INVENTORY_SLOT_ITEMS],
      });
      if (!isReturning) {
        queryClient.invalidateQueries({
          queryKey: [CLIENT_QUERY_KEYS.QUEST_DETAILS],
        });
      }

      openModal(
        MODAL.QUEST_COMPLETE,
        {
          emission: data.resourceEmission,
          reward: data.reward,
          containerId: data.containerId,
          onComplete: isReturning ? onReturn : null,
        },
        {
          fullScreen: true,
        },
      );
    } else {
      queryClient.invalidateQueries({
        queryKey: [CLIENT_QUERY_KEYS.GET_USER_PROFILE],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, data?.reward, data?.containerId, isCompleted]);

  const onReturn = () => {
    openModal(MODAL.QUEST_DETAILS, {
      qid: data?.questId,
    });
  };

  if (retries <= MAX_RETRY || isFailed) {
    return <QuestPendingContent />;
  }

  if (!isCompleted) {
    return <QuestFailedPopup onBack={onReturn} questData={data} />;
  }

  return null;
}
