import { InventorySlotItemType } from '@xborglabs/ui-shared';
import { useQuestDetail } from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { SingleQuest } from '@/modules/quests/components/SingleQuest/SingleQuest';
import { Tag } from '@/modules/shared/components/Atom/Tag';
import { PopupClose } from '@/modules/shared/components/Template/Popups';

import './_AcquisitionRules.scss';
export const AcquisitionRules = ({
  item,
  categoryTitle,
}: {
  item: InventorySlotItemType;
  categoryTitle: string;
}) => {
  const { t } = useTranslation(['inventory']);

  return (
    <div className="acquisition-rules flex column gap-normal">
      <div className="flex end">
        <PopupClose />
      </div>

      <h3 className="modal-title lexend-heading text-center font-bold">
        {t('inventory:acquisitionRuleTitle', {
          rarity: item.rarity,
        })}
      </h3>
      <div className="flex gap-normal middle expand">
        <Image src={item.mediaUrl} width={128} height={128} alt={item.name} />
        <div className="flex column gap-small">
          <div className="flex gap-small middle">
            <div
              className={item.rarity}
              style={{ textTransform: 'capitalize' }}
            >
              <Tag label={item.rarity} slug={''} active disabled />
            </div>
            <Tag label={categoryTitle} slug={''} active disabled />
          </div>
          <h4>{item.name}</h4>
          <div className="lexend-body-s description">{item.description}</div>
        </div>
      </div>
      <div className="flex column gap-normal">
        {item.acquisitionRules.map((rule) => (
          <RuleReward item={item} rule={rule} key={rule.acquisitionRuleId} />
        ))}
      </div>
    </div>
  );
};

function RuleReward({
  rule,
  item,
}: {
  rule: InventorySlotItemType['acquisitionRules'][number];
  item: InventorySlotItemType;
}) {
  const { data: questDetail } = useQuestDetail(rule.rule.questTypeId);

  if (!questDetail) {
    return null;
  }

  return <SingleQuest data={questDetail} />;
}
