import { QUEST_STATUS, QuestDetailsType } from '@xborglabs/ui-shared';
import { useMutateRefreshQuest } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { MouseEventHandler } from 'react';

import { toast } from '@/lib/toastify';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';

export type QuestObjectiveProps = {
  title: string;
  data?: QuestDetailsType;
  objective: boolean;
  subtitle?: string;
  hasRefresh?: boolean;
};

export const QuestObjective = ({
  data,
  title,
  objective,
  subtitle,
  hasRefresh = true,
}: QuestObjectiveProps) => {
  const { t } = useTranslation(['globals']);

  const { isPending, mutate } = useMutateRefreshQuest();
  const hasNotStarted = data?.status === QUEST_STATUS.INACTIVE;

  const refreshQuest: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const id = data?.questId;
    if (!id) return;
    mutate(id, {
      onSuccess: () => toast.success(t('quests:questRefreshComplete')),
    });
  };

  return (
    <div
      className={`quest-objective flex middle between ${
        objective ? 'completed' : 'incomplete'
      }`}
    >
      <div>
        <div className="lexend-body-l">{title}</div>
        {Boolean(subtitle) && (
          <div style={{ opacity: 0.6 }} className="lexend-body-s">
            {subtitle}
          </div>
        )}
      </div>

      {hasRefresh && !objective ? (
        <Button
          variant={BUTTON_VARIANT.TRANSPARENT}
          disabled={hasNotStarted}
          loading={isPending}
          onClick={refreshQuest}
        >
          {t('globals:refresh')}
        </Button>
      ) : null}
    </div>
  );
};
